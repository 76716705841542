export type Icon =
  | 'add'
  | 'add-to-cart'
  | 'add-to-list'
  | 'arrow-left'
  | 'arrow-right'
  | 'box'
  | 'cart'
  | 'check'
  | 'check-circle'
  | 'check-usp'
  | 'checkbox'
  | 'chat'
  | 'chevron-down'
  | 'chevron-right'
  | 'chevron-right-lg'
  | 'circle'
  | 'clock'
  | 'close'
  | 'credit-card'
  | 'crossed-location'
  | 'curved-arrow'
  | 'details'
  | 'edit'
  | 'euro'
  | 'error'
  | 'exclamation-circle'
  | 'external'
  | 'eye'
  | 'eye-hide'
  | 'filter'
  | 'flash'
  | 'hammer'
  | 'heart'
  | 'heart-outline'
  | 'helmet'
  | 'help'
  | 'history'
  | 'home'
  | 'house'
  | 'icon'
  | 'information'
  | 'invoice'
  | 'list'
  | 'location'
  | 'location-arrow'
  | 'logo'
  | 'mail-add'
  | 'mail-like'
  | 'mail-open'
  | 'marker'
  | 'menu'
  | 'minus'
  | 'newspaper'
  | 'no-flash'
  | 'pdf'
  | 'percent'
  | 'phone'
  | 'play'
  | 'plus'
  | 'return'
  | 'scan'
  | 'scan-black'
  | 'scanner-close'
  | 'search'
  | 'share'
  | 'shovel'
  | 'small-right-arrow'
  | 'status-elipse'
  | 'screw'
  | 'trash'
  | 'truck'
  | 'user'
  | 'user-logged-in'
  | 'warning'
  | 'whatsapp'
  | 'wrench';

export function isIcon(value: string): value is Icon {
  return (
    [
      'add',
      'add-to-cart',
      'add-to-list',
      'arrow-left',
      'arrow-right',
      'box',
      'cart',
      'check',
      'check-circle',
      'check-usp',
      'checkbox',
      'chat',
      'chevron-down',
      'chevron-right',
      'chevron-right-lg',
      'circle',
      'clock',
      'close',
      'credit-card',
      'crossed-location',
      'curved-arrow',
      'details',
      'edit',
      'euro',
      'error',
      'exclamation-circle',
      'external',
      'eye',
      'eye-hide',
      'filter',
      'flash',
      'hammer',
      'heart',
      'heart-outline',
      'helmet',
      'help',
      'history',
      'home',
      'house',
      'icon',
      'information',
      'invoice',
      'list',
      'location',
      'location-arrow',
      'logo',
      'mail-add',
      'mail-like',
      'mail-open',
      'marker',
      'menu',
      'minus',
      'newspaper',
      'no-flash',
      'pdf',
      'percent',
      'phone',
      'play',
      'plus',
      'return',
      'scan',
      'scan-black',
      'scanner-close',
      'search',
      'share',
      'shovel',
      'small-right-arrow',
      'status-elipse',
      'screw',
      'trash',
      'truck',
      'user',
      'user-logged-in',
      'warning',
      'whatsapp',
      'wrench',
    ] as Icon[]
  ).includes(value as Icon);
}
