/* tslint:disable */
/* eslint-disable */
/**
 * Groenhart API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0.27
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { LocationButtonApiModel } from './LocationButtonApiModel';
import {
    LocationButtonApiModelFromJSON,
    LocationButtonApiModelFromJSONTyped,
    LocationButtonApiModelToJSON,
} from './LocationButtonApiModel';
import type { LocationContactPersonApiModel } from './LocationContactPersonApiModel';
import {
    LocationContactPersonApiModelFromJSON,
    LocationContactPersonApiModelFromJSONTyped,
    LocationContactPersonApiModelToJSON,
} from './LocationContactPersonApiModel';
import type { OpeningTimeApiModel } from './OpeningTimeApiModel';
import {
    OpeningTimeApiModelFromJSON,
    OpeningTimeApiModelFromJSONTyped,
    OpeningTimeApiModelToJSON,
} from './OpeningTimeApiModel';

/**
 * 
 * @export
 * @interface LocationDetailApiModel
 */
export interface LocationDetailApiModel {
    /**
     * 
     * @type {string}
     * @memberof LocationDetailApiModel
     */
    email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LocationDetailApiModel
     */
    phoneNumber?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof LocationDetailApiModel
     */
    comeAndGo?: boolean | null;
    /**
     * 
     * @type {Array<LocationContactPersonApiModel>}
     * @memberof LocationDetailApiModel
     */
    contactPersons?: Array<LocationContactPersonApiModel> | null;
    /**
     * 
     * @type {Array<OpeningTimeApiModel>}
     * @memberof LocationDetailApiModel
     */
    openingTimes?: Array<OpeningTimeApiModel> | null;
    /**
     * 
     * @type {Array<LocationButtonApiModel>}
     * @memberof LocationDetailApiModel
     */
    locationButtons?: Array<LocationButtonApiModel> | null;
    /**
     * 
     * @type {string}
     * @memberof LocationDetailApiModel
     */
    id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LocationDetailApiModel
     */
    name?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof LocationDetailApiModel
     */
    isFavorite?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof LocationDetailApiModel
     */
    latitude?: number | null;
    /**
     * 
     * @type {number}
     * @memberof LocationDetailApiModel
     */
    longitude?: number | null;
    /**
     * 
     * @type {string}
     * @memberof LocationDetailApiModel
     */
    imageUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LocationDetailApiModel
     */
    zipCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LocationDetailApiModel
     */
    city?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LocationDetailApiModel
     */
    address?: string | null;
}

/**
 * Check if a given object implements the LocationDetailApiModel interface.
 */
export function instanceOfLocationDetailApiModel(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function LocationDetailApiModelFromJSON(json: any): LocationDetailApiModel {
    return LocationDetailApiModelFromJSONTyped(json, false);
}

export function LocationDetailApiModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): LocationDetailApiModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'email': !exists(json, 'email') ? undefined : json['email'],
        'phoneNumber': !exists(json, 'phoneNumber') ? undefined : json['phoneNumber'],
        'comeAndGo': !exists(json, 'comeAndGo') ? undefined : json['comeAndGo'],
        'contactPersons': !exists(json, 'contactPersons') ? undefined : (json['contactPersons'] === null ? null : (json['contactPersons'] as Array<any>).map(LocationContactPersonApiModelFromJSON)),
        'openingTimes': !exists(json, 'openingTimes') ? undefined : (json['openingTimes'] === null ? null : (json['openingTimes'] as Array<any>).map(OpeningTimeApiModelFromJSON)),
        'locationButtons': !exists(json, 'locationButtons') ? undefined : (json['locationButtons'] === null ? null : (json['locationButtons'] as Array<any>).map(LocationButtonApiModelFromJSON)),
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'isFavorite': !exists(json, 'isFavorite') ? undefined : json['isFavorite'],
        'latitude': !exists(json, 'latitude') ? undefined : json['latitude'],
        'longitude': !exists(json, 'longitude') ? undefined : json['longitude'],
        'imageUrl': !exists(json, 'imageUrl') ? undefined : json['imageUrl'],
        'zipCode': !exists(json, 'zipCode') ? undefined : json['zipCode'],
        'city': !exists(json, 'city') ? undefined : json['city'],
        'address': !exists(json, 'address') ? undefined : json['address'],
    };
}

export function LocationDetailApiModelToJSON(value?: LocationDetailApiModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'email': value.email,
        'phoneNumber': value.phoneNumber,
        'comeAndGo': value.comeAndGo,
        'contactPersons': value.contactPersons === undefined ? undefined : (value.contactPersons === null ? null : (value.contactPersons as Array<any>).map(LocationContactPersonApiModelToJSON)),
        'openingTimes': value.openingTimes === undefined ? undefined : (value.openingTimes === null ? null : (value.openingTimes as Array<any>).map(OpeningTimeApiModelToJSON)),
        'locationButtons': value.locationButtons === undefined ? undefined : (value.locationButtons === null ? null : (value.locationButtons as Array<any>).map(LocationButtonApiModelToJSON)),
        'id': value.id,
        'name': value.name,
        'isFavorite': value.isFavorite,
        'latitude': value.latitude,
        'longitude': value.longitude,
        'imageUrl': value.imageUrl,
        'zipCode': value.zipCode,
        'city': value.city,
        'address': value.address,
    };
}

