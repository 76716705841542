/* tslint:disable */
/* eslint-disable */
/**
 * Groenhart API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0.27
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BasketApiModel } from './BasketApiModel';
import {
    BasketApiModelFromJSON,
    BasketApiModelFromJSONTyped,
    BasketApiModelToJSON,
} from './BasketApiModel';

/**
 * 
 * @export
 * @interface UpdateBasketResponseModel
 */
export interface UpdateBasketResponseModel {
    /**
     * 
     * @type {boolean}
     * @memberof UpdateBasketResponseModel
     */
    success?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateBasketResponseModel
     */
    errorMessage?: string | null;
    /**
     * 
     * @type {BasketApiModel}
     * @memberof UpdateBasketResponseModel
     */
    basket?: BasketApiModel;
}

/**
 * Check if a given object implements the UpdateBasketResponseModel interface.
 */
export function instanceOfUpdateBasketResponseModel(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function UpdateBasketResponseModelFromJSON(json: any): UpdateBasketResponseModel {
    return UpdateBasketResponseModelFromJSONTyped(json, false);
}

export function UpdateBasketResponseModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateBasketResponseModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'success': !exists(json, 'success') ? undefined : json['success'],
        'errorMessage': !exists(json, 'errorMessage') ? undefined : json['errorMessage'],
        'basket': !exists(json, 'basket') ? undefined : BasketApiModelFromJSON(json['basket']),
    };
}

export function UpdateBasketResponseModelToJSON(value?: UpdateBasketResponseModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'success': value.success,
        'errorMessage': value.errorMessage,
        'basket': BasketApiModelToJSON(value.basket),
    };
}

