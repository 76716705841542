/* tslint:disable */
/* eslint-disable */
/**
 * Groenhart API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0.27
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BasketApiModel } from './BasketApiModel';
import {
    BasketApiModelFromJSON,
    BasketApiModelFromJSONTyped,
    BasketApiModelToJSON,
} from './BasketApiModel';

/**
 * 
 * @export
 * @interface RemoveVoucherCodeResponseModel
 */
export interface RemoveVoucherCodeResponseModel {
    /**
     * 
     * @type {boolean}
     * @memberof RemoveVoucherCodeResponseModel
     */
    success?: boolean | null;
    /**
     * 
     * @type {BasketApiModel}
     * @memberof RemoveVoucherCodeResponseModel
     */
    basket?: BasketApiModel;
}

/**
 * Check if a given object implements the RemoveVoucherCodeResponseModel interface.
 */
export function instanceOfRemoveVoucherCodeResponseModel(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function RemoveVoucherCodeResponseModelFromJSON(json: any): RemoveVoucherCodeResponseModel {
    return RemoveVoucherCodeResponseModelFromJSONTyped(json, false);
}

export function RemoveVoucherCodeResponseModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): RemoveVoucherCodeResponseModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'success': !exists(json, 'success') ? undefined : json['success'],
        'basket': !exists(json, 'basket') ? undefined : BasketApiModelFromJSON(json['basket']),
    };
}

export function RemoveVoucherCodeResponseModelToJSON(value?: RemoveVoucherCodeResponseModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'success': value.success,
        'basket': BasketApiModelToJSON(value.basket),
    };
}

